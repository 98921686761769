import { PageProps } from "gatsby"
import React from "react"
import { ThemeProvider } from "styled-components"
import Divider from "../components/divider"
import AdvancedHeader from "../components/header/advanced"
import PageHeading from "../components/page-heading"
import Seo from "../components/seo"
import { FlexColumn } from "../components/system/flex-column"
import { FlexRow } from "../components/system/flex-row"
import { Typography } from "../components/system/typography"
import GlobalStyles from "../styles/globals"
import * as Styled from "../styles/pages/authors"
import { blueTheme } from "../styles/themes"
import Logo from "../images/vnlab-logo.inline.svg"
import Details from "../components/details"
import { up } from "styled-breakpoints"
import ScrollUpCTA from "../components/ScrollUpCTA"
import { StaticImage } from "gatsby-plugin-image"
import ContinueLink from "../components/continue-link"
import Mdx from "../components/mdx"

const AuthorsPage: React.FC<PageProps> = () => (
  <ThemeProvider theme={blueTheme}>
    <Seo title="Autorzy" />
    <GlobalStyles variant="blue" />
    <AdvancedHeader divider progress={<ContinueLink />} />
    <Styled.Layout as="main">
      <PageHeading>Autorzy</PageHeading>
      <Styled.Spacer />
      <Styled.Content>
        <Styled.Authors>
          <FlexColumn flex={{ md: 4 }}>
            <Typography
              as="h2"
              variant="caption"
              mt="0 !important"
              mb="1.9rem !important"
              style={{ textTransform: "uppercase", whiteSpace: "pre-line" }}
            >
              {"Tekst, koncepcja graficzna, \n projekt strony"}
            </Typography>
            <Details
              title="Maja Starakiewicz"
              id="text_author"
              forceOpenBreakpoint={up("md")}
            >
              Doktor sztuki, projektantka graficzna, ilustratorka, autorka
              tekstów o projektowaniu. Autorka rysunkowej adaptacji Eposu o
              Gilgameszu. Prowadzi zajęcia na Wydziale Sztuki ANS w Tarnowie
              oraz w Pracowni Rysunku Narracyjnego na ASP w Krakowie. Interesuje
              się przekładem literatury na formy wizualne oraz grami wideo.
            </Details>
          </FlexColumn>
          <FlexColumn flex={{ md: 1 }} role="separator" />
          <FlexColumn flex={{ md: 4 }}>
            <Typography
              as="h2"
              variant="caption"
              mt={{ xs: "6rem", md: "0 !important" }}
              mb="1.9rem !important"
              style={{ textTransform: "uppercase", whiteSpace: "pre-line" }}
            >
              {"Koncepcja graficzna, \n wykonanie grafik i animacji"}
            </Typography>
            <Details
              title="Jakub Woynarowski"
              id="graphics_author"
              forceOpenBreakpoint={up("md")}
            >
              Doktor sztuki, interdyscyplinarny artysta, niezależny kurator,
              projektant oraz ilustrator. Absolwent i wykładowca ASP w Krakowie.
              Realizuje projekty z pogranicza teorii i praktyki wizualnej. Autor
              koncepcji artystycznej wystawy w Pawilonie Polskim podczas 14
              Międzynarodowego Biennale Architektury w Wenecji. Laureat
              Paszportu Polityki w kategorii sztuk wizualnych.
            </Details>
          </FlexColumn>
        </Styled.Authors>
        <Divider my={{ xs: "6rem", md: "11.4rem" }} />
        <FlexRow>
          <FlexColumn flex={{ md: 4 }}>
            <Typography
              as="h3"
              variant="caption"
              mt="0 !important"
              mb="1.9rem !important"
              style={{ textTransform: "uppercase" }}
            >
              Redakcja
            </Typography>
            <Typography variant="body" typo="azo" mb="4.9rem !important">
              Martyna Nowicka
            </Typography>
            <Typography
              as="h3"
              variant="caption"
              mt="0 !important"
              mb="1.9rem !important"
              style={{ textTransform: "uppercase" }}
            >
              Korekta
            </Typography>
            <Typography variant="body" typo="azo" mb="4.9rem !important">
              Adam Ladziński
            </Typography>
          </FlexColumn>
          <FlexColumn flex={{ md: 1 }} role="separator" />
          <FlexColumn flex={{ md: 4 }}>
            <Typography
              as="h3"
              variant="caption"
              mt="0 !important"
              mb="1.9rem !important"
              style={{ textTransform: "uppercase" }}
            >
              Kierownik projektu
            </Typography>
            <Typography variant="body" typo="azo" mb="4.9rem !important">
              Krzysztof Pijarski
            </Typography>
            <Typography
              as="h3"
              variant="caption"
              mt="0 !important"
              mb="1.9rem !important"
              style={{ textTransform: "uppercase" }}
            >
              Programowanie
            </Typography>
            <Typography variant="body" typo="azo" mb="4.9rem !important">
              Piotr Karczewski
            </Typography>
            <Typography
              as="h3"
              variant="caption"
              mt="0 !important"
              mb="1.9rem !important"
              style={{ textTransform: "uppercase" }}
            >
              Koordynacja, <br></br> redakcja wersji cyfrowej
            </Typography>
            <Typography variant="body" typo="azo" mb="4.9rem !important">
              Tytus Szabelski-Różniak
            </Typography>
          </FlexColumn>
        </FlexRow>
        <Divider my={{ xs: "6rem", md: "11.4rem" }} />
        <FlexRow>
          <Typography variant="body" typo="azo">
            Publikacja powstała w ramach działań Pracowni Narracji
            interaktywnych, Cyfrowych Publikacji i UX w Laboratorium Narracji
            Wizualnych przy Łódzkiej Szkole Filmowej. Pracownia poświęcona jest
            badaniu i rozwojowi nowych form narracji, powstających w wyniku
            wykorzystania cyfrowych technologii interaktywnych w filmie
            dokumentalnym i formach pokrewnych takich jak reportaż czy esej
            filmowy.
          </Typography>
        </FlexRow>
        <FlexRow mt="2rem">
          <Typography variant="body" typo="azo">
            Projekt finansowany w ramach programu Ministra Edukacji i Nauki pod
            nazwą <Mdx.q>Regionalna Inicjatywa Doskonałości</Mdx.q> w latach
            2019–2023 nr projektu 023/RID/2018/19, kwota finansowania
            11 865 100 zł. 
          </Typography>
        </FlexRow>
        <FlexRow mt={{ xs: "7.5rem", md: "9.5rem" }}>
          <FlexColumn flex={{ md: 4 }}>
            <Logo
              role="img"
              aria-label="vnLab - Laboratorium Narracji Wizualnych - Pracownia Narracji Interaktywnych"
            />
            <FlexColumn mt="3rem">
              <StaticImage
                style={{ maxWidth: 400, height: "auto" }}
                imgStyle={{ maxWidth: 400, height: "auto" }}
                src="../images/ministerstwo.png"
                alt="Ministerstwo Edukacji i Nauki"
                objectFit="contain"
                objectPosition="0px -20px"
                placeholder="blurred"
              />
            </FlexColumn>
          </FlexColumn>
          <FlexColumn flex={{ md: 1 }} role="separator" />
          <Styled.Publisher mt={{ xs: "7.5rem" }}>
            <Typography
              as="h2"
              variant="caption"
              mt="0 !important"
              mb="1.9rem !important"
            >
              WYDANIE II (CYFROWE):
            </Typography>
            <Typography variant="body" typo="azo">
              Wydawnictwo PWSFTviT w Łodzi, <br />
              ul. Targowa 61/63, 90-323 Łódź
            </Typography>
            <Typography variant="body" typo="azo">
              2023
            </Typography>
            <Typography variant="body" typo="azo" mb="2rem">
              ISBN: 978-83-67397-16-2
            </Typography>
            <Typography
              as="h2"
              variant="caption"
              mt="4.9rem !important"
              mb="1.9rem !important"
            >
              WYDANIE I (PAPIEROWE):
            </Typography>
            <Typography variant="body" typo="azo">
              Korporacja Ha!art, Kraków 2019
            </Typography>
          </Styled.Publisher>
        </FlexRow>
        <FlexRow mt="5rem">
          <FlexColumn flex={{ md: 1 }} role="separator" />
        </FlexRow>
        <Divider
          mt={{ xs: "6rem", md: "11.4rem" }}
          mb={{ xs: "3.4rem", md: "7.5rem" }}
        />
        <ScrollUpCTA mt="4rem" />
      </Styled.Content>
      <Divider
        mt={{ xs: "4rem", md: "7rem", lg: "9.5rem" }}
        mb={{ xs: "5rem", md: "7rem" }}
      />
    </Styled.Layout>
  </ThemeProvider>
)

export default AuthorsPage
