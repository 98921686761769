import { between, up } from "styled-breakpoints"
import styled from "styled-components"
import { FlexColumn } from "../../components/system/flex-column"
import { FlexRow } from "../../components/system/flex-row"

export const Layout = styled(FlexRow)`
  flex-wrap: wrap;
  padding: 0 1.8rem;
  background: ${({ theme }) => theme.colors.blue};

  ${between("md", "xl")} {
    padding: 0 3.5rem;
  }

  ${between("xl", "xxl")} {
    padding: 0 6.5rem;
  }

  ${up("xxl")} {
    padding: 0 14rem;
  }
`

export const Spacer = styled(FlexColumn)`
  display: none;
  flex: 1;
  padding-left: 5vw;

  ${up("lg")} {
    display: initial;
    flex: 3;
  }
`

export const Content = styled(FlexColumn)`
  flex: 1;
  padding-right: 5vw;
  padding-left: 5vw;

  ${up("lg")} {
    flex: 9;
    padding-left: 0;
  }
`

export const Authors = styled(FlexRow)`
  padding-top: 7rem;

  ${up("md")} {
    padding-top: 11.4rem;
  }
`

export const Publisher = styled(FlexColumn)`
  margin-top: 5.1rem;

  ${up("md")} {
    margin-top: 0;
    flex: 4;
  }
`
