import React, { PropsWithChildren, useEffect } from "react"
import { useAnimation, useCycle, useSpring } from "framer-motion"
import * as Styled from "./styles"
import useBreakpoint from "../../hooks/useBreakpoint"
import ArrowHead from "../../images/arrow-head-down.inline.svg"
import getSupportedFitContent from "../../util/getSupportedFitContent"

type Props = {
  title: string
  id: string
  forceOpenBreakpoint?: Function
}

const Details: React.FC<PropsWithChildren<Props>> = ({
  title,
  children,
  id,
  forceOpenBreakpoint: breakpoint,
}) => {
  const [isOpen, cycleIsOpen] = useCycle(false, true)
  const summaryKey = `summary_${id}`
  const detailsKey = `details_${id}`
  const controls = useAnimation()
  const variants = getVariants()
  const isOpenForced = !!breakpoint && useBreakpoint(breakpoint)
  const isHidden = isOpenForced ? false : !isOpen
  const rotateY = useSpring(0)

  const toggle = () => {
    if (isOpenForced) return

    cycleIsOpen()
  }

  const onKeyPress: React.KeyboardEventHandler = ({ key }) => {
    if (key === "Enter") toggle()
  }

  useEffect(() => {
    const variant = isOpen ? "visible" : "hidden"
    controls.start(variant)
    const rotate = isOpen ? -180 : 0
    rotateY.set(rotate)
    return () => controls.stop()
  }, [isOpen])

  return (
    <Styled.DetailsContainer>
      <Styled.Summary
        role="button"
        tabIndex={0}
        id={summaryKey}
        aria-expanded={isOpen}
        aria-controls={detailsKey}
        onClick={toggle}
        onKeyPress={onKeyPress}
        disabled={isOpenForced}
      >
        <Styled.SummaryText>{title}</Styled.SummaryText>
        <Styled.Arrow style={{ rotate: rotateY }} $breakpoint={breakpoint}>
          <ArrowHead role="img" aria-label="Rozwiń" />
        </Styled.Arrow>
      </Styled.Summary>
      <Styled.AnimationContainer>
        <Styled.ExpandAnimation
          $breakpoint={breakpoint}
          initial="hidden"
          animate={controls}
          variants={variants}
          transition={{ duration: 0.4, ease: "easeOut" }}
          aria-hidden={isHidden}
        >
          <Styled.Content
            id={detailsKey}
            aria-labelledby={summaryKey}
            role="region"
          >
            {children}
          </Styled.Content>
        </Styled.ExpandAnimation>
      </Styled.AnimationContainer>
    </Styled.DetailsContainer>
  )
}

export default Details

function getVariants() {
  const fitContent = getSupportedFitContent()

  return {
    hidden: { opacity: 0, height: 0 },
    visible: { opacity: 1, height: fitContent },
  }
}
