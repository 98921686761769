import React, { useCallback } from "react"
import { usePageContext } from "../../context/page"
import { PropsFrom } from "../../types/utils"
import * as Styled from "./styles"

type Props = PropsFrom<typeof Styled.Container>

const ScrollUpCTA: React.FC<Props> = props => {
  const { id } = usePageContext()

  const scroll = useCallback(() => {
    const container = document.getElementById(`page__container--${id}`)
    if (!container) return

    container.scrollTo({ top: 0, behavior: "smooth" })
  }, [id])

  return (
    <Styled.Container {...props}>
      <Styled.ScrollButton onClick={scroll}>
        Powrót do góry
        <Styled.ArrowUpIcon role="img" aria-label="Strzałka do góry" />
      </Styled.ScrollButton>
    </Styled.Container>
  )
}

export default ScrollUpCTA
