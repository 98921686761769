import { motion } from "framer-motion"
import { up } from "styled-breakpoints"
import styled, { css } from "styled-components"
import { Typography } from "../system/typography"

export const DetailsContainer = styled.article``

export const Summary = styled(Typography).attrs({
  as: "header",
  variant: "body",
  typo: "azo",
})<{ disabled?: boolean }>`
  cursor: pointer;
  margin: 0;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
      pointer-events: none;
    `};
`

export const Content = styled(Typography).attrs({
  variant: "body",
  typo: "azo",
})`
  display: block;
  margin: 0;

  padding-top: 2rem;

  ${up("md")} {
    padding-top: 4.9rem;
  }
`

export const AnimationContainer = styled.div`
  overflow: hidden;
`

export const ExpandAnimation = styled(motion.div)<{
  $breakpoint?: ReturnType<typeof up>
}>`
  ${({ $breakpoint: breakpoint }) =>
    !!breakpoint &&
    css`
      ${breakpoint} {
        height: fit-content !important;
        height: -webkit-fit-content !important;
        height: -moz-fit-content !important;
        opacity: 1 !important;
      }
    `};
`

export const SummaryText = styled.span`
  margin-right: 1rem;
`

export const Arrow = styled(motion.span)<{
  $breakpoint?: ReturnType<typeof up>
}>`
  display: inline-block;
  ${({ $breakpoint: breakpoint }) =>
    !!breakpoint &&
    css`
      ${breakpoint} {
        display: none;
      }
    `};
`
