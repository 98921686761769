import styled from "styled-components"
import { FlexRow } from "../system/flex-row"
import ArrowUp from "../../images/arrow-up.inline.svg"
import { Typography } from "../system/typography"
import { underlineHoverStyles } from "../mdx"
import { PropsFrom } from "../../types/utils"
import { space, SpaceProps } from "styled-system"

export const Container = styled(FlexRow)<SpaceProps>`
  ${space}

  justify-content: flex-end;
  text-align: end;
  text-transform: uppercase;
`

export const ScrollButton = styled(Typography)`
  ${underlineHoverStyles}

  margin: 0;
  font-weight: 500;
`

ScrollButton.defaultProps = {
  as: "button",
  variant: "caption",
} as PropsFrom<typeof ScrollButton>

export const ArrowUpIcon = styled(ArrowUp)`
  height: 1em;
  vertical-align: baseline;
  margin-left: 1rem;

  & path {
    stroke: currentColor;
    fill: currentColor;
  }
`
